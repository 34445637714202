import { createStore } from "vuex";

const store = createStore({
  state: {
    token: null,
    user: null,

    
    clients: [],
    payments:[],
    schools:[],
    students:[],
    secondaryStudents:[],
    voters:[],
    collegeStudents:[],

    
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    removeToken(state) {
      state.token = null;
    },
    removeUser(state) {
      state.user = null;
    },
    
    setSchools(state, schools) {
      state.schools = schools;
    },
    setStudents(state, students) {
      state.students = students;
    },
    setSecondarystudents(state,secondaryStudents){
      state.secondaryStudents = secondaryStudents

    },
    setCollegestudents(state,collegeStudents){
      state.collegeStudents = collegeStudents

    },

    setVoters(state,voters){
      state.voters = voters

    },
    setClients(state, clients) {
      state.clients = clients;
    },
    setPayments(state, payments) {
      state.payments = payments;
    },
    


   
  },
  actions: {
    clearToken(context) {
      context.commit("removeToken");
    },
    clearUser(context) {
      context.commit("removeUser");
    },
    addToken(context) {
      context.commit("setToken");
    },

    async LoadStudents(context) {
      const url = `https://api.roberms.com/nyamira/get/students`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const students = [];
      for (const key in responseData) {
        const student = {
          id: key,
          student_names: responseData[key].student_names,
          student_id: responseData[key].student_id,
          student_phone: responseData[key].student_phone,
          student_date_of_birth: responseData[key].student_date_of_birth,
          student_admission_number: responseData[key].student_admission_number,
          year_of_admission: responseData[key].year_of_admission,
          annual_fee: responseData[key].annual_fee,
          campus_name: responseData[key].campus_name,
          mode_of_study: responseData[key].mode_of_study,
          sub_location: responseData[key].sub_location,
          mother_names: responseData[key].mother_names,
          mother_id: responseData[key].mother_id,
          mother_phone: responseData[key].mother_phone,
          father_names: responseData[key].father_names,
          father_id: responseData[key].father_id,
          father_phone: responseData[key].father_phone,
          guardian_names: responseData[key].guardian_names,
          guardian_id: responseData[key].guardian_id,
          guardian_phone: responseData[key].guardian_phone,
          school_name: responseData[key].school_name,
          school_level: responseData[key].school_level,
          school_type: responseData[key].school_type,
          needy_status: responseData[key].needy_status,
          tracking_code: responseData[key].tracking_code,
          amount_awarded: responseData[key].amount_awarded,
          ward: responseData[key].ward,
          processed: responseData[key].processed,
          reg_date: responseData[key].reg_date,
          
        };
        students.push(student);
      }
      context.commit("setStudents", students);
    },
    async LoadSecondarystudents(context) {
      const url = `https://api.roberms.com/nyamira/get/secondary/students`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const students = [];
      for (const key in responseData) {
        const student = {
          id: key,
          student_names: responseData[key].student_names,
          student_nemis: responseData[key].student_nemis,
          main_contact_number: responseData[key].main_contact_number,
          student_yob: responseData[key].student_yob,
          student_admission_number: responseData[key].student_admission_number,
          year_of_admission: responseData[key].year_of_admission,
          annual_fee: responseData[key].annual_fee,
          student_class: responseData[key].student_class,
          year_of_completion: responseData[key].year_of_completion,
          student_gender: responseData[key].student_gender,
          
          sub_location: responseData[key].sub_location,
          mother_names: responseData[key].mother_names,
          mother_id: responseData[key].mother_id,
          mother_phone: responseData[key].mother_phone,
          mother_yob: responseData[key].mother_yob,
          father_names: responseData[key].father_names,
          father_id: responseData[key].father_id,
          father_phone: responseData[key].father_phone,
          father_yob: responseData[key].father_yob,
          guardian_names: responseData[key].guardian_names,
          guardian_id: responseData[key].guardian_id,
          guardian_phone: responseData[key].guardian_phone,
          guardian_yob: responseData[key].guardian_yob,
          school_name: responseData[key].school_name,
          school_box: responseData[key].school_box,
          school_physical_address: responseData[key].school_physical_address,
          school_phone: responseData[key].school_phone,
          school_type: responseData[key].school_type,

          needy_status: responseData[key].needy_status,
          tracking_code: responseData[key].tracking_code,
          amount_awarded: responseData[key].amount_awarded,
          ward: responseData[key].ward,
          occupation: responseData[key].occupation,
          processed: responseData[key].processed,
          processed_by: responseData[key].processed_by,
          reg_date: responseData[key].reg_date,
          
        };
        students.push(student);
      }
      context.commit("setSecondarystudents", students);
    },
    async LoadCollegestudents(context) {
      const url = `https://api.roberms.com/nyamira/get/college/students`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const students = [];
      for (const key in responseData) {
        const student = {
          id: key,
          student_names: responseData[key].student_names,
          student_id: responseData[key].student_id,
          student_phone: responseData[key].student_phone,
          student_yob: responseData[key].student_yob,
          student_admission_number: responseData[key].student_admission_number,
          year_of_admission: responseData[key].year_of_admission,
          annual_fee: responseData[key].annual_fee,
          student_course: responseData[key].student_course,
          student_campus: responseData[key].student_campus,
          student_level: responseData[key].student_level,
          year_of_completion: responseData[key].year_of_completion,
          student_gender: responseData[key].student_gender,
          main_contact_number: responseData[key].main_contact_number,
          
          sub_location: responseData[key].sub_location,
          mother_names: responseData[key].mother_names,
          mother_id: responseData[key].mother_id,
          mother_phone: responseData[key].mother_phone,
          mother_yob: responseData[key].mother_yob,
          father_names: responseData[key].father_names,
          father_id: responseData[key].father_id,
          father_phone: responseData[key].father_phone,
          father_yob: responseData[key].father_yob,
          guardian_names: responseData[key].guardian_names,
          guardian_id: responseData[key].guardian_id,
          guardian_phone: responseData[key].guardian_phone,
          guardian_yob: responseData[key].guardian_yob,
          school_name: responseData[key].school_name,
          school_box: responseData[key].school_box,
          college_physical_address: responseData[key].college_physical_address,
          school_phone: responseData[key].school_phone,
          school_type: responseData[key].school_type,
          college_name:responseData[key].college_name,
          college_type:responseData[key].college_type,

          needy_status: responseData[key].needy_status,
          tracking_code: responseData[key].tracking_code,
          amount_awarded: responseData[key].amount_awarded,
          ward: responseData[key].ward,
          occupation: responseData[key].occupation,
          processed: responseData[key].processed,
          processed_by: responseData[key].processed_by,
          reg_date: responseData[key].reg_date,
          
        };
        students.push(student);
      }
      context.commit("setCollegestudents", students);
    },
    async LoadVoters(context) {
      const url = `https://api.roberms.com/nyamira/get/voters`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const voters = [];
      for (const key in responseData) {
        const voter = {
          id: key,
          id_number: responseData[key].id_number,
          names: responseData[key].name,
          ward: responseData[key].ward,
          polling_station: responseData[key].polling_station,
          admission_number: responseData[key].admission_number,
         
          
        };
        voters.push(voter);
      }
      context.commit("setVoters", voters);
    },

    async getStudents(context) {
      const url = `https://api.roberms.com/nyamira/get/students/:code`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const trips = [];
      for (const key in responseData) {
        const trip = {
          id: key,
          vehicle: responseData[key].vehicle,
          route: responseData[key].route,      
          weight: responseData[key].weight,
          date: responseData[key].date,
          amount: responseData[key].amount
        };
        trips.push(trip);
      }
      context.commit("VehicleTrips", trips);
    },

    async studentForms(context) {
      const url = `https://api.roberms.com/nyamira/get/students/:code`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const deductions = [];
      for (const key in responseData) {
        const deduction = {
          id: key,
          vehicle: responseData[key].vehicle,
          type: responseData[key].type,      
          money: responseData[key].money,
          notes: responseData[key].notes,
          date: responseData[key].date
        };
        deductions.push(deduction);
      }
      context.commit("Vehicledeductions", deductions);
    },
    
    async LoadSchools(context) {
      const url = `https://api.roberms.com/nyamira/get/schools`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const courts = [];
      for (const key in responseData) {
        const court = {
          id: key,
          name: responseData[key].name,
          area: responseData[key].area,
          reg_date: responseData[key].reg_date,
        };
        courts.push(court);
      }
      context.commit("setCourts", courts);
    },
    

    async ClientPayments(context) {
      const url = `https://api.roberms.com/nyamira/get/payments`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const payments = [];
      for (const key in responseData) {
        const payment = {
          id: key,
          client_Id: responseData[key].client_id,
          
          payment_date: responseData[key].payment_date,
          amount: responseData[key].amount,
        };
        payments.push(payment);
      }
      context.commit("setClientpayments", payments);
    },
    async LoadPayments(context) {
      const url = `https://api.roberms.com/nyamira/get/payments`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      console.log(responseData)
      const payments = [];
      for (const key in responseData) {
        const payment = {
          id: key,
          client_id: responseData[key].client_id,
          name: responseData[key].name,
          amount: responseData[key].amount,
          date: responseData[key].payment_date,
        };
        payments.push(payment);
      }
      context.commit("setPayments", payments);
    },
  },
  getters: {
    isLoggedIn() {
      return !!localStorage.getItem("token");
    },

    clients: (state) => state.clients,
    revenues: (state) => state.revenues,
    allstudents: (state) => state.students,
    secondaryStudents:(state)=>state.secondaryStudents,
    collegeStudents:(state)=>state.collegeStudents,
    collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),
    secondaryItibostudent:(state)=>state.secondaryStudents.filter(area=>area.ward=='Itibo'),
    collegeEkerenyostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Ekerenyo'),
    secondaryEkerenyostudent:(state)=>state.secondaryStudents.filter(area=>area.ward=='Ekerenyo'),
    collegeMagwagwastudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Magwagwa'),
    secondaryMagwagwastudent:(state)=>state.secondaryStudents.filter(area=>area.ward=='Magwagwa'),
    collegeBomwagamostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Bomwagamo'),
    secondaryBomwagamostudent:(state)=>state.secondaryStudents.filter(area=>area.ward=='Bomwagamo'),
    collegeBokeirastudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Bokeira'),
    secondaryBokeirastudent:(state)=>state.secondaryStudents.filter(area=>area.ward=='Bokeira'),
    //needy status 
    collegeOrphan:(state)=>state.collegeStudents.filter(needy=>needy.needy_status=='Is Total oprhan'),
    secondaryOrphan:(state)=>state.secondaryStudents.filter(needy=>needy.needy_status=='Is Total oprhan'),
    collegeOneparent:(state)=>state.collegeStudents.filter(needy=>needy.needy_status=='Has one parent'),
    SecondaryOneparent:(state)=>state.secondaryStudents.filter(needy=>needy.needy_status=='Has one parent'),
    collegeBothparents:(state)=>state.collegeStudents.filter(needy=>needy.needy_status=='Has both parents'),
    SecondaryBothparent:(state)=>state.secondaryStudents.filter(needy=>needy.needy_status=='Has both parents'),



    payments: (state) => state.payments,
    voters: (state)=>state.voters,
    // ClientPayments: (state) => state.clientPayments,
    clientPaymentGetter: (state) => (client)=>state.clientpaymentz.filter(pay=>pay.client_id==client),
    PaymentGetter: (state) => (client)=>state.payments.filter(pay=>pay.client_id==client),
    clientName: (state) => (client)=> state.clients.filter(pay=>pay.client_id==client),
    // clientName: (state) => (client)=> state.clients.filter(pay=pay.client_id=client)
    formGetter: (state) => (code)=>state.students.filter(snumber=>snumber.tracking_code==code),
    studentGetter: (state) => (sid)=>state.students.filter(snumber=>snumber.student_id==sid),
    secondarystudentsGetter:(state)=>(nemis)=>state.secondaryStudents.filter(nemisnumber=>nemisnumber.student_nemis==nemis),
    collegestudentsGetter:(state)=>(nemis)=>state.collegeStudents.filter(nemisnumber=>nemisnumber.student_id==nemis),

    voterGetter: (state)=>(vid)=>state.voters.filter(vnumber=>vnumber.id_number==vid),

  },


  //
});
export default store;