// import { Router } from "express";
import { createRouter, createWebHistory } from "vue-router";
import store from "./store"

// import pages
import LoginUser from "./components/pages/LoginUser.vue";
import RegisterUser from "./components/pages/RegisterUser.vue";
import HomePage from "./components/pages/HomePage.vue";
import UserProfile from "./components/pages/UserProfile.vue";
import DashBoard from "./components/pages/DashBoard"
import TestHome from "./components/pages/TestHome"


import schools from "./components/pages/schools"
import students from "./components/pages/students"
import ApplyCdf from "./components/pages/applyCdf"

import Reports from "./components/pages/reports"

import StudentForms from "./components/pages/StudentForms"
import StudentDetails from "./components/pages/StudentDetails"
import Voters from "./components/pages/voters"

import secondaryApplication from "./components/pages/secondaryApplication"
import collegeApplication from "./components/pages/collegeApplication"
import universityApplication from "./components/pages/universityApplications"
import applicationForm from "./components/pages/applicationForm"
import highschoolForm from "./components/pages/highschoolForm"
import CollegeForm from "./components/pages/collegeForm"

import applicationFormsearch from "./components/pages/applicationFormsearch"
import collegeFormsearch from "./components/pages/collegeSearch"

import secondaryApplicants from "./components/pages/SecondaryApplicants"
import collegeApplicants from "./components/pages/collegeApplicants"




const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomePage },
    { path: "/login", component: LoginUser },
    { path: "/register", component: RegisterUser },
    { path: "/profile", component: UserProfile, meta: {
      requiresAuth: true
    } },
    {path: "/dashboard", component:DashBoard, meta: {
      requiresAuth: true
    }},
    {path: "/apps", component:DashBoard, meta: {
      requiresAuth: true
    }},
    {path: "/test",component:TestHome},
    {path: "/reports",component:Reports},
    
    
    
    {path: "/schools", component: schools,meta: {
      requiresAuth: true
    }},
    {path: "/students", component: students,meta: {
      requiresAuth: true
    } },
    {path: "/student/:id", component: StudentDetails, meta: {
      requiresAuth: true
    }},
    {path: "/form/:student_id", component: StudentForms},
    {path:"/apply/bursary", component:ApplyCdf},
    {path:"/secondary/application", component:secondaryApplication},
    {path:"/college/application", component:collegeApplication},
    {path:"/university/application", component:universityApplication},
    {path: "/application/form/:id", component: applicationForm},
    {path: "/application/form", component: applicationFormsearch},
    {path: "/college/form", component: collegeFormsearch},
    {path:"/secondary/application/form/:id",component:highschoolForm},
    {path:"/college/application/form/:id",component:CollegeForm},
    {path: "/voters",component: Voters, meta: {
      requiresAuth: true
    }},

    {path:"/secondary/students",component:secondaryApplicants, meta: {
      requiresAuth: true
    }},
    {path:"/college/students",component:collegeApplicants, meta: {
      requiresAuth: true
    }},
   
    

  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({ path: '/Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router;
