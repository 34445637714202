<template>
  <div id="">
    <main-navigation></main-navigation>
    <base-card>
      <h3 class="d-flex justify-content-center text-info">
        Bursary Application form for Secondary School Forms I and II only
      </h3>

      <p class="text-danger">
        Fill all details carefully with CAPITAL LETTERS. Fill all details
        neccessary to help us decide. Incorrect answers will lead to automatic
        disqualification
      </p>
      <form @submit.prevent="createStudent">
        <div class="students">
          <strong>STUDENT DETAILS</strong>
          <div v-if="errors.length > 0">
            <ul>
              <p class="text-info text-center">
                Collect the following error and submit
              </p>
              <li
                start="1"
                class="text-danger"
                v-for="error in errors"
                :key="error.id"
              >
                {{ error }}
              </li>
            </ul>
          </div>

          <form>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <!-- <small v-if="!nameIsValid" class="text-danger text-muted"> Student name is required</small> -->

                <input
                  type="text"
                  class="form-control"
                  placeholder="Student Names"
                  v-model="formData.student_names"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Birth certificate Number"
                  v-model="formData.student_nemis"
                  v-model.trim="student_nemis"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <select
                  class="form-control"
                  v-model="formData.student_gender"
                  required
                >
                  <option disabled value="">Select student Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Year of birth"
                  v-model="formData.student_yob"
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Institution Name"
                  v-model="formData.school_name"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="student admission number"
                  v-model="formData.student_admission_number"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <select class="form-control" v-model="formData.student_class">
                  <option disabled value="">select class</option>
                  <!-- <option>Form 1</option> -->
                  <!-- <option>From 2</option> -->
                  <option>Form 3</option>
                  <option>From 4</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Annual fee"
                  v-model="formData.annual_fee"
                  required
                />
              </div>
            </div>

            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Institution P.O BOX Address"
                  v-model="formData.school_box"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Institution physical address and county"
                  v-model="formData.school_physical_address"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <select
                  class="form-control"
                  v-model="formData.school_type"
                  required
                >
                  <option disabled value="">Select institution type</option>
                  <option>Boardinng secondary</option>
                  <option>Day school secondary</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Institution phone number"
                  v-model="formData.school_phone"
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Year of admission"
                  v-model="formData.year_of_admission"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="expected year of completeion"
                  v-model="formData.year_of_completion"
                  required
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <select class="form-control" v-model="formData.processed_by">
                  <option disabled value="">
                    select if student has dissability
                  </option>
                  <option>Living with dissability</option>
                  <option>No dissability</option>
                </select>
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Phone number parent/guardian"
                  v-model="formData.main_contact_number"
                  required
                />
              </div>
            </div>
          </form>
        </div>
        <div class="parents">
          <strong>PARENT DETAILS</strong>
          <br />
          <small>
            Fill details of parents who are available. Leave blank if that
            parent is not available and fill the gurdian details
          </small>
          <br />
          <div class="form-row"></div>
          <div class="form-row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <select
                class="form-control"
                v-model="formData.needy_status"
                required
              >
                <option disabled value="">Select One</option>
                <option>Has both parents</option>
                <option>Has one parent</option>
                <option>Is Total oprhan</option>
              </select>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
               <select class="form-control" v-model="formData.ward" required>
                 <option disabled value="">select ward</option>
                 <option>BOGICHORA</option>
                <option>BOKEIRA</option>
                <option>BOMWAGAMO</option>
                <option>BONYAMATUTA</option>
                <option>BOSAMARO</option>
                <option>EKERENYO</option>
                <option>ESISE</option>
                <option>GACHUBA</option>
                <option>GESIMA</option>
                <option>ITIBO</option>
                <option>KEMERA</option>
                <option>KIABONYORU</option>
                <option>RIGOMA</option>
                <option>MAGOMBO</option>
                <option>MAGWAGWA</option>
                <option>MANGA</option>
                <option>MEKENENE</option>
                <option>NYAMAIYA</option>
                <option>NYANSIONGO</option>
                <option>TOWNSHIP</option>
               </select>
             </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Sub location"
                v-model="formData.sub_location"
                required
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Mother Names"
                v-model="formData.mother_names"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Mother Id number"
                v-model="formData.mother_id"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Mother phone number"
                v-model="formData.mother_phone"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Mother year of Birth"
                v-model="formData.mother_yob"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Father Names"
                v-model="formData.father_names"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Father Id number"
                v-model="formData.father_id"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="father phone number"
                v-model="formData.father_phone"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Father year of Birth"
                v-model="formData.father_yob"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Guardian Names"
                v-model="formData.guardian_names"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Guardian Id number"
                v-model="formData.guardian_id"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Guardian phone number"
                v-model="formData.guardian_phone"
              />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <input
                type="text"
                class="form-control"
                placeholder="Guardian year of Birth"
                v-model="formData.guardian_yob"
              />
            </div>
          </div>
          Brief honest narration of parent/guardian occupation and why you
          deserve the bursary

          <div class="">
            <div class="">
              <textarea
                cols="1"
                rows="3"
                class="form-control"
                placeholder="Parent/Guardian occupation - Give us your story"
                v-model="formData.occupation"
              ></textarea>
            </div>
          </div>

          <br />
        </div>

        <br />

        <div class="form-row">
          <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
            <base-button>Submit</base-button>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
            <router-link to="/apply/bursary"> Go back to instructions</router-link>
          </div>
        </div>
      </form>
    </base-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      user: "",
      errors: [],

      formData: {
        student_names: "",
        student_id: "",
        student_phone: "",
        student_yob: "",
        student_admission_number: "",
        student_nemis: "",
        student_class: "",
        school_type: "",
        school_phone: "",
        year_of_admission: "",
        main_contact_number: "",
        annual_fee: "",
        campus_name: "",
        mode_of_study: "",
        sub_location: "",
        course_of_study: "",
        student_gender: "",
        processed_by: "",

        mother_names: "",
        mother_id: "",
        mother_phone: "",
        mother_yob: "",
        father_names: "",
        father_id: "",
        father_phone: "",
        father_yob: "",
        guardian_names: "",
        guardian_id: "",
        guardian_phone: "",
        guardian_yob: "",

        school_name: "",

        needy_status: "",
        ward: "",
        occupation: "",
      },
    };
  },

  methods: {
    async createStudent() {
      const formIsValid =
        this.nameIsValid &&
        this.nemisIsValid &&
        this.formData.student_yob &&
        this.formData.student_admission_number &&
        this.formData.school_name;
      if (formIsValid) {
        const response = await axios.post(
          "https://api.roberms.com/nyamira/secondary/student/application",
          this.formData
        );
        console.log(response);
        //  this.errors.push("Kindly fill all the fields highlighted")

        await this.$router.replace("/application/form");
        // console.log(this.formData);
      } else {
        this.errors = [];
        if (!this.formData.student_names) {
          this.errors.push("student name is missing");
        }
        if (!this.formData.student_yob) {
          this.errors.push("student year of birth is missing");
        }
        if (!this.formData.student_nemis) {
          this.errors.push("student birth certificate number is missing");
        }
        if (!this.formData.student_admission_number) {
          this.errors.push("student admission number is missing");
        }
        if (!this.formData.student_yob) {
          this.errors.push("student year of birth is missing");
        }
        if (!this.formData.school_name) {
          this.errors.push("school name is missing");
        }
        if (!this.formData.student_class) {
          this.errors.push("student class is missing");
        }
        if (!this.formData.main_contact_number) {
          this.errors.push("parent phone number is missing");
        }
        if (!this.formData.school_typ) {
          this.errors.push("school type is missing");
        }

        console.log("form is invalid are invalid");
      }
    },
  },
  watch: {
    needy_status(value) {
      this.needy_status = value;
    },
  },
  computed: {
    nemisIsValid() {
      return this.formData.student_nemis;
    },
    nameIsValid() {
      return this.formData.student_names;
    },
  },
};
</script>

<style scoped>
#body1 {
  width: 100vw;
  /* background-color: #eaf8d0c9; */
  /* position: fixed; */
  /* margin-left: -220px; */
  /* z-index: 999; */
  justify-content: center;
  align-items: center;
}
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type="checkbox"] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}
/* input:invalid,
select:invalid,
textarea:invalid {
  border-color: rgb(240, 177, 177);
} */

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}

input[type="checkbox"]:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>

<script></script>
