<template>
    <div id="body1">
     <base-card>
    <center> <h3 class=".text-center">NORTH MUGIRANGO HIGH SCHOOL, COLLEGES AND UNIVERSITIES DIGITAL CDF APPLICATION FORM</h3></center>
     
       <strong>
         
         Fill all details carefully with CAPITAL LETTERS. Omit the ones that are not
         applicable</strong
       >
       <form @submit.prevent="createStudent">
         <div class="students">
           <strong>STUDENT DETAILS</strong>
           <form>
             <div class="form-row">
               <div class="col">
                 <input 
                   type="text"
                   class="form-control"
                   placeholder="Student Names"
                   v-model="formData.student_names"
                   
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Admission Number"
                   v-model="formData.student_admission_number"
                 />
               
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Contact phone number"
                   v-model="formData.student_phone"
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Date of birth (yyyy/mm/dd)"
                   v-model="formData.student_date_of_birth"
                 />
               </div>
             </div>
             <div class="form-row">
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="School Name"
                   v-model="formData.school_name"
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Nemis number/ID number for colleges"
                   v-model="formData.student_id"
                 />
                 
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Class/Year of study"
                 />
               </div>
               <div class="col">
                 <select class="form-control" v-model="formData.school_level">
                   <option disabled value="">Please select school type</option>
                   <option>Boarding Secondary</option>
                   <option>College</option>
                   <option>University</option>
                 </select>
               </div>
             </div>
             For university and college
               <br>
             <div class="form-row">
               
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Year of admission"
                   v-model="formData.year_of_admission"
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="course of study"
                   v-model="formData.course_of_study"
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Annual fee"
                   v-model="formData.annual_fee"
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Campus name"
                   v-model="formData.campus_name"
                 />
               </div>
               <!-- <div class="col"> -->
                 <!-- <input
                   type="text"
                   class="form-control"
                   placeholder="Course of study"
                   v-model="formData.course_of_study"
                 /> -->
               <!-- </div> -->
               <div class="col">
                 <select class="form-control" v-model="formData.mode_of_study">
                   <option disabled value="">Please select one</option>
                   <option>Regular</option>
                   <option>Parallel</option>
                   
                 </select>
                
               </div>
             </div>
           </form>
         </div>
         <div class="parents">
         <strong>PARENT DETAILS</strong>  
         <br>
         <small> Fill details of parents who are available. Leave blank if that parent is not available and fill the gurdian details </small>
           <br>
         <div class="form-row">
                         
             
             </div>
             <div class="form-row">
               <div class="col">
                 <select class="form-control" v-model="formData.needy_status">
                   <option disabled value="">Please select One</option>
                   <option>Has both parents</option>
                   <option>Singel parent</option>
                   <option>Total oprhan</option>
                 </select>
               </div>
               <div class="col">
                 <select class="form-control" v-model="formData.ward">
                   <option disabled value="">Please select ward</option>
                   <option>Itibo</option>
                   <option>Ekerenyo</option>
                   <option>Bokeira</option>
                   <option>Magwagwa</option>
                   <option>Bomwagamo</option>
                 </select>
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Sub location"
                   v-model="formData.sub_location"
                 />
               </div>
               
              
             </div>
             <div class="form-row">
               <div class="col">
                 <input 
                   type="text"
                   class="form-control"
                   placeholder="Mother Names"
                   v-model="formData.mother_names"
                   
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Mother Id number"
                   v-model="formData.mother_id"
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Mother phone number"
                   v-model="formData.mother_phone"
                 />
               </div>
             </div>
             <div class="form-row">
               <div class="col">
                 <input 
                   type="text"
                   class="form-control"
                   placeholder="Fathe Names"
                   v-model="formData.father_names"
                   
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Father Id number"
                   v-model="formData.father_id"
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="father phone number"
                   v-model="formData.father_phone"
                 />
               </div>
             </div>
             <div class="form-row">
               <div class="col">
                 <input 
                   type="text"
                   class="form-control"
                   placeholder="Guardian Names"
                   v-model="formData.guardian_names"
                   
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Guardian Id number"
                   v-model="formData.guardina_id"
                 />
               </div>
               <div class="col">
                 <input
                   type="text"
                   class="form-control"
                   placeholder="Guardian phone number"
                   v-model="formData.guardian_phone"
                 />
               </div>
             </div>
             
         <br>
         
        
             
           
         </div>
        
         
 
        <br>
 
         
 
        
         
        
 
         
 
         <!-- <div class="form-control">
           <label for="registered_by"> reg by</label>
           <input type="text"  id="registered_by" v-model="formData.registered_by" />
         </div> -->
 
         <base-button>Submit</base-button>
       </form>
     </base-card>
   </div>
 </template>
 
 <script>
 import axios from "axios";
 export default {
   data() {
     return {
       user: "",
 
       formData: {
        student_names: "",
        student_id: "",
        student_phone: "",
        student_date_of_birth: "",
        student_admission_number:"",
        year_of_admission:"",
        annual_fee: "",
        campus_name:"",
        mode_of_study:"",
        sub_location:"",
        course_of_study:"",
 
         mother_names: "",
         mother_id: "",
         mother_phone: "",
         father_names: "",
         father_id: "",
         father_phone: "",
         Guardian_names: "",
         Guardian_id: "",
         guardian_phone: "",
         
         school_name: "",
         school_level: "",
         
         needy_status: "",
         ward: "",
         
       },
     };
   },
   // computed: {
   //   courts() {
   //     return this.$store.getters.allCourts;
   //   },
   // },
   // created() {
   //   this.$store.dispatch("LoadCourts");
   // },
   //  mounted() {
   // let user = localStorage.getItem('user');
   // this.user = JSON.parse(user)
 
   // },
   methods: {
     async createStudent() {
       const response = await axios.post(
         "https://api.roberms.com/cdf/student/registration",
         this.formData
       );
       console.log(response);
 
       this.$router.replace("/");
       // console.log(this.formData);
     },
   },
 };
 </script>
 
 <style scoped>
 
 #body1{
     width: 100vw;
     /* background-color: #eaf8d0c9; */
     /* position: fixed; */
     margin-left: -220px;
     /* z-index: 999; */
     justify-content: center;
     align-items: center;
   
   }
 .form-control {
   margin: 0.5rem 0;
 }
 
 label {
   font-weight: bold;
   display: block;
   margin-bottom: 0.5rem;
 }
 
 input[type="checkbox"] + label {
   font-weight: normal;
   display: inline;
   margin: 0 0 0 0.5rem;
 }
 
 input,
 textarea {
   display: block;
   width: 100%;
   border: 1px solid #ccc;
   font: inherit;
 }
 
 input:focus,
 textarea:focus {
   background-color: #f0e6fd;
   outline: none;
   border-color: #3d008d;
 }
 
 input[type="checkbox"] {
   display: inline;
   width: auto;
   border: none;
 }
 
 input[type="checkbox"]:focus {
   outline: #3d008d solid 1px;
 }
 
 h3 {
   margin: 0.5rem 0;
   font-size: 1rem;
 }
 
 .invalid label {
   color: red;
 }
 
 .invalid input,
 .invalid textarea {
   border: 1px solid red;
 }
 </style>
 
 <script></script>
 