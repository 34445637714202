<template>
    <div>
     <main-navigation></main-navigation>
    <base-card>
<table class="table table-striped table-bordered">
        <thead>
            <tr >
                <!-- <th><h3>Client Id</h3></th> -->
                <th><h6>Name</h6></th>
                <th><h6>Institution Name</h6></th>
                <th><h6>Admission Number</h6></th>
                <th><h6>Id Number</h6></th>
                <th><h6>level of study</h6></th>
                <th><h6>Ward</h6></th>
                <th><h6>Needy status</h6></th>
                <th><h6>Amount awarded</h6></th>
                <!-- <th><h6>Application date</h6></th> -->
            </tr>
            </thead> 
            <tbody>
                <tr v-for="student in students.slice().reverse()" :key="student.id">
                    
                    <!-- <td>{{ payment.client_id}}</td> -->
                    <td>{{ student.student_names }}</td>
                    <td>{{ student.college_name}}</td>

                    <td>{{ student.student_admission_number}}</td>
                    <td>{{ student.student_id}}</td>
                    <td>{{ student.student_level}}</td>
                    <td>{{ student.ward}}</td>
                    
                    <td>{{ student.needy_status}}</td>
                    <td>{{ student.amount_awarded}}</td>
                    <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
                    
                </tr>
            </tbody>
    </table>
    
</base-card>

<!-- <router-link to="/receive/payment">Receive payment</router-link> -->
</div>
</template>
<script>



export default {
    computed: {
        students() {
            return  this.$store.getters.collegeStudents

            
        }

    },
     created() {
    this.$store.dispatch('LoadCollegestudents');  
    
    
  },

}

</script>
