<template>
    <div id="body">
      <home-header></home-header>
      <base-card>
        <div id="element-to-convert">
          <div class="row">
            <div class="col-lg-3">
              <img width="150" height="150" src="../../assets/nyamira_logo.png" />
            </div>
  
            <div class="col-lg-9">
             County Government of nyamira
              <br />
              Department of Education and ICT
              <br />
              County Building, First floor, suite 7
              <br />
              Email: ceceducation@nyamiracounty.or.ke, website nyamiracounty.or.ke
            </div>
          </div>
          <small> 
            <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                
             <b class="text-danger fst-italic">FORM SERIAL NUMBER:  C{{ studentdetail.id }} </b> 
               
              </div>  </small> 
         <center> <b class="text-danger text-center">
          Bursary Application form for colleges and Universities
          </b> </center>
          .............................................................................................................................................................................................................................................................................................................................................................................................................................
          <br />
          <!-- {{ studentdetails }} -->
  
          <b class="text-danger">STUDENT DETAILS</b>
          <div class="row">
            <div class="col-lg-4">
              Student name:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_names }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Admission number:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_admission_number }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Ward:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.ward}}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Student ID number:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_id }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Course of Study:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_course }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Year of study:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_level}}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Gender:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_gender }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Any dissability:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.processed_by }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Student Phone:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                 +254{{ studentdetail.main_contact_number }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Student year of Birth:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_yob }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Year of completion:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.year_of_completion}}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Annual fee:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.annual_fee }}
                </p>
              </div>
            </div>
          </div>
          
          <b class="text-danger">Insitution Details</b>
          
          <div class="row">
            <div class="col-lg-4">
              Institution Name:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.college_name }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Physical Address:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.college_physical_address }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Campus name 
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.student_campus }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Year of Admission
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.year_of_admission }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Institution type:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.college_type }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Institution Contact:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.school_phone }}
                </p>
              </div>
            </div>
          </div>
          <b class="text-danger">PARENT DETAILS</b>
  
          <div class="row">
            <div class="col-lg-4">
              Mother name:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.mother_names }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Mother Id:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.mother_id }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Mother year of Birth:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.mother_yob}}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Father names:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.father_names }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Father Id:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.father_id }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Father year of Birth:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.father_yob }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              Guardian Names:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.guardian_names }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Guardian Id:
              <div
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.guardian_id }}
                </p>
              </div>
            </div>
  
            <div class="col-lg-4">
              Guardian year of Birth:
              <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-primary">
                  {{ studentdetail.guardian_yob }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
                Ocupation and narration


            </div>
            <div
                class="col-lg-8 typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="text-capitalize text-danger">
                 student {{ studentdetail.needy_status }}
                </p>
              </div>
          


          </div>
          <div class="row">
            <div style="color:blue" class="col">
                <div
                class="typed"
                v-for="studentdetail in studentdetails"
                :key="studentdetail.id"
              >
                <p class="">
                  {{ studentdetail.occupation }}
                </p>
              </div>

            </div>
          


          </div>
          <br>
          <div class="html2pdf__page-break"></div>
          <div>
           <b> Attach the following documents:</b>
            <br>
1. Student's transcript
<br>
2. Photocopy of parent/guardian's ID card
<br>
3. Photocopy of Student's National ID/Birth certificate


<br>
5. Current fee statement
<br>
6. School/ institution admission 
<br>
<br>

          </div>

          <div>
          <b class="text-danger">CHIEF/ASS. CHIEF</b>
          <p>Kindly help us verify the information given by the student. Your honest remarks will help in disbursing bursaries to needy students within our consituency since you know the student and the family better</p>

          
          <br>
          <div class="row">
            <div class="col-lg-6">Name: .................................................

            </div>
            <div class="col-lg-6">
              Official stamp:
             
            </div>
            <br>
            <br>
            
        </div>
        <div class="row">
            <br>
            <br>
            <div class="col-lg-6">Remarks: .................................................</div>
            <div class="col-lg-6">
              Signature:
              ................................
            </div>
          </div>
        </div>
          <br />
          <br />

          
          <b> For official use only</b>
          <br>
          <br>
          <div class="row">
            <div class="col-lg-6">Approved By NG-CDF Committee</div>
            <br>
            <div class="col-lg-6">
              Amount Awarded:
            
              ...................................
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-lg-6">
              Name:
              .................................................
            </div>
            <br />
            <br />
            <div class="col-lg-6">
              signature :
              ...............................
            </div>
          </div>
          
        </div>
  
        <!-- <button class="btn btn-primary" @click="exportToPDF">Download form</button> -->
        <!-- <base-button>Download Form</base-button> -->
      </base-card>
      <button class="btn btn-primary" @click="exportToPDF">Download form</button>
    </div>
  </template>
  
  <script>
  import html2pdf from "html2pdf.js";
  
  export default {
    methods: {
      exportToPDF() {
        var element = document.getElementById("element-to-convert");
        html2pdf(element, {
          margin: [5, 15, 10, 15], //top, left, buttom, right
          filename: this.$route.params.id + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            dpi: 192,
            scale: 2,
            logging: true,
            letterRendering: true,
          },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        });
      },
    },
    created() {
      (this.student_id = this.$route.params.id),
        this.$store.dispatch("LoadCollegestudents");
      //this.$store.dispatch("LoadVoters");
    },
    computed: {
      studentdetails() {
        return this.$store.getters.collegestudentsGetter(this.student_id);
      },
      
    },
  };
  </script>
  <style scoped>
  #body {
    width: 98vw;
    /* height: 100vw; */
    /* background-color: #eaf8d0c9; */
    position: absolute;
    margin-top: 1px;
    left: 20px;
    z-index: 999;
    justify-content: center;
    align-items: center;
  }
  </style>
  