<template>
  <div>
    <the-headersider></the-headersider>
    <base-card>
      <!-- <h2>Student details</h2> -->
      <!-- {{ studentdetails }} -->

      <form>
      <strong>STUDENT DETAILS</strong>

        <div class="row">
            <div class="col-sm-4">
              <strong>Student Names: </strong> <br> <small> {{ studentdetails[0].student_names}} </small>
            </div>
            <div class="col-sm-3">
             <strong> Student ID/Nemis no: </strong> <br> <small> {{ studentdetails[0].student_id }} </small>
            </div>
            <div class="col-sm-3">
              <strong>student Year of Birth: </strong> <br> <small>{{ studentdetails[0].student_date_of_birth}} </small> 
            </div>
            <div class="col-sm-2">
             <strong> Student Phone: </strong> <br> <small> {{ studentdetails[0].student_phone }} </small>
            </div>
            <!-- <div class="col-sm-3">.col-sm-3</div> -->
          </div>
          <div class="row">
            <div class="col-sm-4">
              <strong>Ward: </strong> <br> <small> {{ studentdetails[0].ward}} </small>
            </div>
            <div class="col-sm-3">
             <strong> Sub location: </strong> <br> <small> {{ studentdetails[0].sub_location }} </small>
            </div>
            <div class="col-sm-3">
              <strong>Mode of study: </strong> <br> <small>{{ studentdetails[0].mode_of_study}} </small> 
            </div>
            <div class="col-sm-2">
             <strong> Annual fee: </strong> <br> <small> {{ studentdetails[0].annual_fee }} </small>
            </div>
            <!-- <div class="col-sm-3">.col-sm-3</div> -->
          </div>

         
          <div class="row">
            <div class="col-sm-4">
              <strong>School Name: </strong> <br> <small> {{ studentdetails[0].school_name}} </small>
            </div>
            <div class="col-sm-3">
             <strong> School level: </strong> <br> <small> {{ studentdetails[0].school_level }} </small>
            </div>
            <div class="col-sm-3">
              <strong>dmission number: </strong> <br> <small>{{ studentdetails[0].student_admission_number}} </small> 
            </div>
            <div class="col-sm-2">
             <strong> College/campus: </strong> <br> <small> {{ studentdetails[0].campus_name }} </small>
            </div>
            <!-- <div class="col-sm-3">.col-sm-3</div> -->
          </div>
          <br>

      <strong>PARENTS DETAILS</strong> 
          <div v-if="studentdetails[0].mother_names"  class="row">
            <div class="col-sm-4">
              <strong>Mother name: </strong> <br> <small> {{ studentdetails[0].mother_names}} </small>
            </div>
            <div class="col-sm-3">
             <strong> Mother ID: </strong> <br> <small> {{ studentdetails[0].mother_id }} </small>
            </div>
            <div class="col-sm-3">
             <strong> Mother year of birth: </strong> <br> <small> {{ studentdetails[0].mother_YOB }} </small>
            </div>
            <div class="col-sm-2">
              <strong>Mother Phone: </strong> <br> <small>{{ studentdetails[0].mother_phone}} </small> 
            </div>
            
            <!-- <div class="col-sm-3">.col-sm-3</div> -->
          </div>
          <div v-if="studentdetails[0].father_names"  class="row">
            <div class="col-sm-4">
              <strong>Father name: </strong> <br> <small> {{ studentdetails[0].father_names}} </small>
            </div>
            <div class="col-sm-3">
             <strong> Father ID: </strong> <br> <small> {{ studentdetails[0].father_id }} </small>
            </div>
            <div class="col-sm-3">
             <strong> Father year of birth: </strong> <br> <small> {{ studentdetails[0].father_YOB }} </small>
            </div>
            <div class="col-sm-2">
              <strong>Father Phone: </strong> <br> <small>{{ studentdetails[0].father_phone}} </small> 
            </div>
           
            <!-- <div class="col-sm-3">.col-sm-3</div> -->
          </div>
          <div v-if="studentdetails[0].Guardian_names" class="row">
            <div class="col-sm-4">
              <strong>Guardian name: </strong> <br> <small> {{ studentdetails[0].Guardian_names}} </small>
            </div>
            <div class="col-sm-3">
             <strong> Guardian ID: </strong> <br> <small> {{ studentdetails[0].guardian_id }} </small>
            </div> <div class="col-sm-3">
             <strong> Guardian year of birth: </strong> <br> <small> {{ studentdetails[0].guardian_YOB }} </small>
            </div>
            <div class="col-sm-2">
              <strong>Guardian Phone: </strong> <br> <small>{{ studentdetails[0].guardian_phone}} </small> 
            </div>
            
            <!-- <div class="col-sm-3">.col-sm-3</div> -->
          </div>
          <div  class="row">
            <div class="col-sm-4">
              <strong>Needy status: </strong> <br> <small> {{ studentdetails[0].needy_status}} </small>
            </div>
            <div class="col-sm-3">
             <strong> processed: </strong> <br> <small> {{ studentdetails[0].processed }} </small>
            </div>
            <div class="col-sm-3">
             <strong> Processed by: </strong> <br> <small> {{ user }} </small>
            </div>
            <div class="col-sm-2">
              <strong>User remarks: </strong> <br> <small>{{ studentdetails[0].user_remails}} </small> 
            </div>
           
            <!-- <div class="col-sm-3">.col-sm-3</div> -->
          </div>

        








        

        
        
      </form>

      

      <div>
        <b-table> <strong>remarks: </strong> </b-table>
        <!-- <div v-if="voterdetails.length">   <div>{{ voterdetails[0].names }} is a voter at
        {{ voterdetails[0].polling_station }} in {{ voterdetails[0].ward }} Ward</div></div> -->
        <div>Voter details not found</div>

        <div class="form-control">
          <div class="field">
            <label class="label">Amount awarded</label>
            <div class="select">
              <select>
                <option disabled value="">Please select one</option>
                <option>5000</option>
                <option>7000</option>
                <option>10000</option>
                <option>12000</option>
              </select>
            </div>
            <div><input type="textarea" placeholder="Your remairks/reason" /></div>
          </div>
        </div>

        <base-button>Approve Bursary</base-button>
      </div>
    </base-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      student: "",
      student_id: "",
    };
  },
  created() {
    (this.student_id = this.$route.params.id),
      this.$store.dispatch("LoadStudents");
    this.$store.dispatch("LoadVoters");
  },
  computed: {
    studentdetails() {
      return this.$store.getters.studentGetter(this.student_id);
    },
    voterdetails() {
      return this.$store.getters.voterGetter(this.studentdetails[0].student_id);
    },
  },
};
</script>
