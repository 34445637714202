<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">Navbar</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link disabled" href="#">Disabled</a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form>
  </div>
</nav>
  </div>
</template>
<style scoped>
#body1 {
  width: 100vw;
  /* background-color: #eaf8d0c9; */
  /* position: fixed; */
  margin-left: -220px;
  /* z-index: 999; */
  /* justify-content: center;
    align-items: center; */
}
</style>
<script>
// const user = null;
// import axios from 'axios'
// import { mapGetters } from "vuex";
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },

  methods: {
    handleLogout() {
      this.$store.dispatch("clearToken");
      localStorage.removeItem("token"); // clear your user's token from localstorage
      localStorage.removeItem("user"); // clear your user from localstorage

      this.$router.push("/login");
    },
  },
  data() {
    return {
      user: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("user");
    this.user = JSON.parse(user);
  },
  created() {
    // if (localStorage.user) {
    //      this.user = localStorage.getItem('user');
    //      console.log(user)
    // }
    // const response = await localStorage.getItem('user');
    // console.log(response)
  },
};
</script>
