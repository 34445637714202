<template>
  <div>
  <the-headersider></the-headersider>

  <input type="text" v-model="search" placeholder="Search students" />
  <div id="element-to-convert">
  <img width="100" height="50" src="../../assets/NMC.png" />

  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th>ID/Nemis number</th>
        <th>Student name</th>
        <!-- <th><h3>Parent name</h3></th>
        <th><h3>phone number</h3></th> -->
        <th>Admission number</th>

        <th>School</th>
        <th>Show details</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="student in students" :key="student.id">
        <td>{{ student.student_id }}</td>
        <td>{{ student.student_names }}</td>
        <!-- <td>{{ student.parent_name }}</td>
        <td>{{ student.phone_number }}</td> -->
        <td>{{ student.student_admission_number }}</td>

        <td>{{ student.school_name }}</td>
        <!-- <td><router-link :to="'/student/' + student.student_id"> Show details</router-link></td> -->
        <!-- <td> <router-link :to="`/student/:{student.id_number}`" class="btn btnn-primary">Details</router-link> </td> -->
      </tr>
    </tbody>
  </table>
  </div>
  <button @click="exportToPDF">Export to PDF</button>
  <br>
  <router-link to="/">Go back</router-link>
</div>
</template>
<script>
import html2pdf from 'html2pdf.js'



export default {
  methods: {
    exportToPDF() {
			html2pdf(document.getElementById('element-to-convert'));
		}


  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    students() {
      return this.$store.getters.allstudents;
    },
    filteredStudents() {
      return this.students
        .filter(
          (student) =>
            student.student_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) |
            student.admission_number
              .toLowerCase()
              .includes(this.search.toLowerCase()) |
            student.id_number
              .toLowerCase()
              .includes(this.search.toLowerCase()) |
            student.phone_number.includes(this.search) |
            student.parent_name
              .toLowerCase()
              .includes(this.search.toLowerCase())
        )
        .slice(0, 10);
    },
  },
  created() {
    this.$store.dispatch("LoadStudents");
  },
};
</script>
<style scoped>
input {
  /* display: block; */
  width: 350px;
  margin: 20px auto;
  padding: 10px 45px;
  background: white no-repeat 15px center;
  background-size: 15px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
</style>
